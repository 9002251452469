import { pagesNames } from "@/router/pages-names";

export default [
  {
    path: "/login",
    text: "Login",
    name: pagesNames.LOGIN,
    visible: false,
    component: () => import("@/views/Login"),
    meta: {
      requiresAuth: false,
    },
  },

  {
    path: "/users",
    text: "Usuários",
    name: pagesNames.USERS_LIST,
    icon: "person",
    visible: true,
    component: () => import("@/views/Users"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/langs",
    text: "Idiomas",
    name: pagesNames.LANGS_LIST,
    icon: "chat",
    visible: true,
    component: () => import("@/views/Langs"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/types",
    text: "Tipos",
    name: pagesNames.TYPES_LIST,
    icon: "category",
    visible: true,
    component: () => import("@/views/Types/List"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/types/order",
    text: "Tipos",
    name: pagesNames.TYPES_ORDER,
    icon: "category",
    visible: false,
    component: () => import("@/views/Types/Order"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/marital-status",
    text: "Estados civis",
    name: pagesNames.MARITAL_STATUS_LIST,
    icon: "supervised_user_circle",
    visible: true,
    component: () => import("@/views/MaritalStatus"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/pathogens",
    text: "Patógenos",
    name: pagesNames.PATHOGENS_LIST,
    icon: "assignment",
    visible: true,
    component: () => import("@/views/Pathogens"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/symptoms",
    text: "Sintomas",
    name: pagesNames.SYMPTOMS_LIST,
    icon: "assignment",
    visible: true,
    component: () => import("@/views/Symptoms"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/points",
    text: "Pontos",
    name: pagesNames.POINTS_LIST,
    icon: "record_voice_over",
    visible: true,
    component: () => import("@/views/Points"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/categories",
    text: "Categorias",
    name: pagesNames.CATEGORIES_LIST,
    icon: "category",
    visible: true,
    component: () => import("@/views/Categories"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/paresbiomagneticos",
    text: "Pares Biomagnéticos",
    name: pagesNames.PARES_BIOMAGNETICOS_LIST,
    icon: "timeline",
    visible: true,
    component: () => import("@/views/ParesBiomagneticos/List"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/paresbiomagneticos/form",
    name: pagesNames.PARES_BIOMAGNETICOS_FORM,
    visible: false,
    component: () => import("@/views/ParesBiomagneticos/PageForm"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/sequences",
    text: "Sequências Rastreio e Reservatório",
    name: pagesNames.SEQUENCES_LIST,
    icon: "list",
    visible: true,
    component: () => import("@/views/Sequences/List"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/sequences/form",
    text: "Sequências",
    name: pagesNames.SEQUENCES_FORM,
    icon: "list",
    visible: false,
    component: () => import("@/views/Sequences/Form"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/points-order",
    text: "Ordenar pontos",
    name: pagesNames.POINTS_ORDER_LIST,
    icon: "reorder",
    visible: false,
    component: () => import("@/views/ParesBiomagneticos/PointsOrder"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/resolution-zone",
    text: "Zonas de Resolução",
    name: pagesNames.RESOLUTION_ZONE_LIST,
    icon: "bubble_chart",
    visible: true,
    component: () => import("@/views/ResolutionZone"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/tumoral-phenomena",
    text: "Fenômenos Tumorais",
    name: pagesNames.TUMORAL_PHENOMENA_LIST,
    icon: "bubble_chart",
    visible: true,
    component: () => import("@/views/TumoralPhenomena"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/vascular-phenomena",
    text: "Fenômenos Vasculares",
    name: pagesNames.VASCULAR_PHENOMENA_LIST,
    icon: "bubble_chart",
    visible: true,
    component: () => import("@/views/VascularPhenomena"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/potential-space",
    text: "Espaços Potenciais",
    name: pagesNames.POTENTIAL_SPACES_LIST,
    icon: "bubble_chart",
    visible: true,
    component: () => import("@/views/PotentialSpaces"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/flow-types",
    text: "Tipos de fluxo",
    name: pagesNames.FLOW_TYPES_LIST,
    icon: "directions",
    visible: true,
    component: () => import("@/views/FlowTypes"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/oligoelements",
    text: "Oligoelementos",
    name: pagesNames.OLIGOELEMENTS_LIST,
    icon: "restaurant",
    visible: true,
    component: () => import("@/views/Oligoelements/List"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/escolas",
    text: "Escolas",
    name: pagesNames.ESCOLAS,
    icon: "restaurant",
    visible: true,
    component: () => import("@/views/Escolas/Escolas"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/oligoelements/order/:typeId",
    text: "Ordenar oligoelementos por tipo",
    name: pagesNames.OLIGOELEMENTS_ORDER_BY_TYPE,
    icon: "restaurant",
    visible: false,
    component: () => import("@/views/Oligoelements/Order"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/chakras",
    text: "Chakras",
    name: pagesNames.CHAKRAS_LIST,
    icon: "gps_fixed",
    visible: true,
    component: () => import("@/views/Chakras"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/chromosomes",
    text: "Cromossomos",
    name: pagesNames.CHROMOSOMES_LIST,
    icon: "format_list_numbered",
    visible: true,
    component: () => import("@/views/Chromosomes"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/homologate-therapist",
    text: "Homologar Terapeuta",
    name: pagesNames.HOMOLOGATE_THERAPISTS_LIST,
    icon: "person_search",
    visible: true,
    component: () => import("@/views/HomologateTherapist/List"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/homologate-therapist/form/:id",
    text: "Homologar Terapeuta",
    name: pagesNames.HOMOLOGATE_THERAPISTS_FORM,
    icon: "person",
    visible: false,
    component: () => import("@/views/HomologateTherapist/Form"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/manage-therapist",
    text: "Gerenciar Terapeutas",
    name: pagesNames.MANAGE_THERAPISTS_LIST,
    icon: "perm_identity",
    visible: true,
    component: () => import("@/views/ManageTherapist/List"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/manage-therapist/form",
    text: "Gerenciar Terapeuta",
    name: pagesNames.MANAGE_THERAPISTS_FORM,
    icon: "person",
    visible: false,
    component: () => import("@/views/ManageTherapist/Form"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/legends",
    text: "Gerenciar Legendas",
    name: pagesNames.LEGENDS_LIST,
    icon: "legend_toggle",
    visible: true,
    component: () => import("@/views/Legends"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/paypal",
    text: "Paypal",
    name: pagesNames.PAYPAL_PLANS_LIST,
    icon: "attach_money",
    visible: true,
    component: () => import("@/views/Paypal/Plans"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/paypal/plan",
    text: "Plano Detalhado Paypal",
    name: pagesNames.PAYPAL_DETAILED_PLAN,
    icon: "attach_money",
    visible: false,
    component: () => import("@/views/Paypal/Plan"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/paypal/plan-users-email",
    text: "Plano para e-mails Paypal",
    name: pagesNames.PAYPAL_PLAN_USERS_EMAIL,
    icon: "attach_money",
    visible: false,
    component: () => import("@/views/Paypal/PlanUsersEmail"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/appointments",
    text: "Agendamentos",
    name: pagesNames.APPOINTMENTS_LIST,
    icon: "book_online",
    visible: true,
    component: () => import("@/views/AppointmentManagement/List"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/therapy-templates",
    text: "Modelos de Terapia",
    name: pagesNames.THERAPY_TEMPLATES_LIST,
    icon: "bookmark",
    visible: true,
    component: () => import("@/views/TherapyTemplates/List"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/therapy-templates/form",
    text: "Formulário Modelo de Terapia",
    name: pagesNames.THERAPY_TEMPLATES_FORM,
    icon: "bookmark",
    visible: false,
    component: () => import("@/views/TherapyTemplates/Form"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/general-settings",
    text: "Config. gerais",
    name: pagesNames.GENERAL_SETTINGS,
    icon: "settings",
    visible: true,
    component: () => import("@/views/GeneralSettings"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/complementary-therapies",
    text: "Terapias Complementares",
    name: pagesNames.COMPLEMENTARY_THERAPIES,
    icon: "chat",
    visible: true,
    component: () => import("@/views/ComplementaryTherapies/List"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/subscriptions",
    text: "Assinaturas",
    name: pagesNames.SUBSCRIPTIONS_LIST,
    icon: "credit_card",
    visible: true,
    component: () => import("@/views/Subscriptions/Subscriptions"),
    meta: {
      requiresAuth: true,
    },
  },
];

export const homeItem = {
  path: "/home",
  text: "Início",
  name: "Home",
  icon: "home",
  visible: true,
  component: () => import("@/views/Home"),
  meta: {
    requiresAuth: true,
  },
};

export const sortMenu = (m1, m2) => {
  if (m1.text > m2.text) {
    return 1;
  }

  if (m1.text < m2.text) {
    return -1;
  }

  return 0;
};
