export const pagesNames = {
  HOME: "HOME",

  LOGIN: "LOGIN",

  USERS_LIST: "USERS_LIST",

  LANGS_LIST: "LANGS_LIST",

  TYPES_LIST: "TYPES_LIST",
  TYPES_ORDER: "TYPES_ORDER",

  MARITAL_STATUS_LIST: "MARITAL_STATUS_LIST",

  PATHOGENS_LIST: "PATHOGENS_LIST",

  SYMPTOMS_LIST: "SYMPTOMS_LIST",

  POINTS_LIST: "POINTS_LIST",

  CATEGORIES_LIST: "CATEGORIES_LIST",

  PARES_BIOMAGNETICOS_LIST: "PARES_BIOMAGNETICOS_LIST",
  PARES_BIOMAGNETICOS_FORM: "PARES_BIOMAGNETICOS_FORM",

  SEQUENCES_LIST: "SEQUENCES_LIST",

  SEQUENCES_FORM: "SEQUENCES_FORM",

  POINTS_ORDER_LIST: "POINTS_ORDER_LIST",

  RESOLUTION_ZONE_LIST: "RESOLUTION_ZONE_LIST",

  TUMORAL_PHENOMENA_LIST: "TUMORAL_PHENOMENA_LIST",

  VASCULAR_PHENOMENA_LIST: "VASCULAR_PHENOMENA_LIST",

  POTENTIAL_SPACES_LIST: "POTENTIAL_SPACES_LIST",

  FLOW_TYPES_LIST: "FLOW_TYPES_LIST",

  OLIGOELEMENTS_LIST: "OLIGOELEMENTS_LIST",
  OLIGOELEMENTS_ORDER_BY_TYPE: "OLIGOELEMENTS_ORDER_BY_TYPE",

  CHAKRAS_LIST: "CHAKRAS_LIST",

  CHROMOSOMES_LIST: "CHROMOSOMES_LIST",

  HOMOLOGATE_THERAPISTS_LIST: "HOMOLOGATE_THERAPISTS_LIST",
  HOMOLOGATE_THERAPISTS_FORM: "HOMOLOGATE_THERAPISTS_FORM",

  MANAGE_THERAPISTS_LIST: "MANAGE_THERAPISTS_LIST",
  MANAGE_THERAPISTS_FORM: "MANAGE_THERAPISTS_FORM",

  LEGENDS_LIST: "LEGENDS_LIST",

  PAYPAL_PLANS_LIST: "PAYPAL_PLANS_LIST",
  PAYPAL_DETAILED_PLAN: "PAYPAL_DETAILED_PLAN",
  PAYPAL_PLAN_USERS_EMAIL: "PAYPAL_PLAN_USERS_EMAIL",

  APPOINTMENTS_LIST: "APPOINTMENTS_LIST",

  THERAPY_TEMPLATES_LIST: "THERAPY_TEMPLATES_LIST",
  THERAPY_TEMPLATES_FORM: "THERAPY_TEMPLATES_FORM",

  ESCOLAS: "ESCOLAS",

  GENERAL_SETTINGS: "GENERAL_SETTINGS",
  COMPLEMENTARY_THERAPIES: "COMPLEMENTARY_THERAPIES",
  SUBSCRIPTIONS_LIST: "SUBSCRIPTIONS_LIST",
};
