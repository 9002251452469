<template>
  <div>
    <perseu-card
      :title="
        this.subscription.subscriptionId
          ? 'Editar Assinatura'
          : 'Nova Assinatura'
      "
    >
      <template #title-left>
        <back-button @click="close" />
      </template>

      <template #content>
        <v-form ref="form">
          <v-select
            label="Plano"
            v-model="subscription.paypalPlanId"
            :items="plans"
            item-text="name"
            item-value="idPaypal"
            :rules="[rules.required]"
          />

          <v-select
            label="Usuário"
            v-model="subscription.userId"
            :items="users"
            item-text="name"
            item-value="id"
            :rules="[rules.required]"
          />

          <v-select
            label="Status"
            v-model="subscription.status"
            item-text="label"
            item-value="key"
            :items="statuses"
            :rules="[rules.required]"
          />
          <!-- Conditional Cancellation Reason Field -->
          <v-textarea
            v-if="subscription.status === 'CANCELED'"
            label="Motivo do Cancelamento"
            v-model="subscription.cancellationReason"
            :rules="[rules.required]"
            placeholder="Informe o motivo do cancelamento"
          ></v-textarea>
          <div>
            <v-menu ref="menu" v-model="menu" :close-on-content-click="false">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="formattedDate"
                  label="Data de expiração"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :rules="[rules.required]"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date"
                @input="updateFormattedDate"
                locale="pt-BR"
              ></v-date-picker>
            </v-menu>
          </div>
        </v-form>
      </template>

      <template #actions>
        <v-btn color="secondary" @click="save">Salvar</v-btn>
      </template>
    </perseu-card>
  </div>
</template>

<script>
import api from "@/services/api-service";
import { findAll } from "@/services/users-management-service";
import { getPlans } from "@/services/paypal-service";

import moment from "moment";

export default {
  props: {
    selectedSubscription: {
      type: Object,
      default: () => ({
        name: "",
        // Initialize additional subscription properties here
        visible: true,
      }),
    },
  },
  mounted() {
    this.getUsers();
    this.getPlans();
    if (this.subscription.nextBillingTime) {
      this.date = moment(
        this.subscription.nextBillingTime,
        "DD/MM/YYYY"
      ).format("YYYY-MM-DD");
    } else {
      this.date = moment().format("YYYY-MM-DD");
    }
  },

  data() {
    return {
      subscription: { ...this.selectedSubscription },
      rules: {
        required: (value) => !!value || "Este campo é obrigatório",
        // Add more validation rules if necessary
      },
      users: [],
      statuses: [
        { key: "APPROVAL_PENDING", label: "Pendente de aprovação" },
        { key: "CREATED", label: "Criada" },
        { key: "ACTIVE", label: "Ativa" },
        { key: "SUSPENDED", label: "Suspensa" },
        { key: "CANCELED", label: "Cancelada" },
        { key: "EXPIRED", label: "Expirada" },
      ],
      formattedDate: this.selectedSubscription.nextBillingTime,
      menu: false,
      date: "",
      plans: [],
    };
  },

  methods: {
    updateFormattedDate(date) {
      this.formattedDate = moment(date).format("DD/MM/YYYY");
      this.menu = false;
    },
    async save() {
      try {
        this.$store.dispatch("loading/openDialog");

        // Validate the form before proceeding
        if (!this.$refs.form.validate()) return;
        console.log(this.subscription);
        const selectedPlan = this.plans.find(
          (plan) => plan.idPaypal === this.subscription.paypalPlanId
        );

        const request = {
          subscriptionId: this.subscription.subscriptionId,
          productType: selectedPlan.productType,
          userId: this.subscription.userId,
          status: this.subscription.status,
          nextBillingTime: this.date,
          paypalPlan: selectedPlan.idPaypal,
          lastPrice: selectedPlan.price,
        };


        // Include cancellationReason if status is CANCELED
        if (this.subscription.status === "CANCELED") {
          request.cancellationReason = this.subscription.cancellationReason;
        }else{
          request.cancellationReason = null;
        }

        if (this.subscription.subscriptionId) {
          // Update existing subscription
          const { data } = await api().put(`/paypal/subscriptions`, request);
          this.$emit("updated", data);
        } else {
          // Create new subscription

          const { data } = await api().put("/paypal/subscriptions", request);
          this.$emit("created", data);
        }

        // Show success notification
        this.$toasted.global.defaultSuccess();
      } catch (error) {
        // Handle errors appropriately
        this.$errorHandler(error);
      } finally {
        // Close the loading dialog
        this.$store.dispatch("loading/closeDialog");
      }
    },
    close() {
      // Emit event to close the form modal
      this.$emit("closed");
    },
    async getUsers() {
      try {
        let { data } = await findAll(this.filters);
        this.users = data;
      } catch (error) {
        this.$errorHandler(error);
      }
    },
    async getPlans() {
      try {
        let { data } = await getPlans();
        this.plans = data;
      } catch (error) {
        this.$errorHandler(error);
      }
    },
  },
  watch: {
    // Reset cancellationReason if status changes from CANCELED to something else
    "subscription.status"(newStatus, oldStatus) {
      if (newStatus !== "CANCELED") {
        this.subscription.cancellationReason = "";
      }
    },
  },
};
</script>

<style scoped>
/* Add any component-specific styles here */
</style>
